var coll = document.getElementsByClassName("collapsible");
var i;

for (i = 0; i < coll.length; i++) {
	coll[i].addEventListener("click", function () {
		this.classList.toggle("active");
		var answer = this.nextElementSibling;
		if (answer.style.display === "block") {
			answer.style.display = "none";
			this.parentElement.classList.remove("active");
		} else {
			answer.style.display = "block";
			this.parentElement.classList.add("active");
		}
	});
}
